import * as formik from 'formik';
import { useState } from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from 'react-toastify';
import { defaulttoastOptions } from '../../common/const/const';
import { Menu, Table } from '../../components';
import useGetFetch from '../../hooks/useGetTable';
import useTable from '../../hooks/useTableControllers';
import { EmailIcetexData, EmailicetexDataDetail } from '../../interfaces/emailData.interface';
import { PaginationType } from '../../interfaces/response.interface';
import { FilterModel, TableModel } from '../../interfaces/table.interface';
import { createFilterModel, defaultPagination, getColumns, onDownloadExcelData, onFetchEmailsIcetexData, onFetchEmailsIcetexDetailData } from './checkEmailIcetex.const';
import { checkEmailIcetexSchema, checkEmailIcetexType } from './checkEmailIcetex.schema';
import CustomModal from './checkEmailIcetexDetails';

//TODO: integrar los filtro a los fetch
const CheckEmailIcetex: React.FC = () => {

    const { Formik } = formik;
    const [showModal, setShowModal] = useState(false);
    const [filterModel, setFilterModel] = useState<FilterModel<EmailIcetexData>[]>([]);
    const [emailDataDetail, setEmailDataDetail] = useState<EmailicetexDataDetail>();
    document.title = "Consulta emails";
    const [loadingExcelDownload, setLoadingExcelDownload] = useState<boolean>(false)

    const onDetail = async (id: number): Promise<void> => {
        await onFetchEmailsIcetexDetailData(id).then(
            (emailDataDetail) => {
                setEmailDataDetail(emailDataDetail);
                setShowModal(true);
            }
        ).catch((error) => {
            toast.error(error?.response?.data?.message, defaulttoastOptions);
        });
    }

    //TODO: use the loading, error and data
    const {
        loading,
        error,
        data: process,
        handleData,
    } = useGetFetch<PaginationType<EmailIcetexData>, TableModel<EmailIcetexData>>(onFetchEmailsIcetexData, {
        paginationModel: defaultPagination,
        filterModel,
    });

    const tableControllers = useTable<EmailIcetexData>(
        {
            filterModel,
            paginationModel: process
                ? { ...defaultPagination }
                : defaultPagination,
        },
        handleData,
    );

    const onDownloadExcel = async () => {
        setLoadingExcelDownload(true);
        const currentDate = new Date().toLocaleDateString();
        const blob = await onDownloadExcelData(filterModel)
            .catch((error) => {
                toast.error(error?.response?.data?.message, defaulttoastOptions);
            });
        // Crear un blob a partir de la respuesta
        const blobResponse = new Blob([blob], { type: 'text/csv;charset=utf-8;' });
        // Crear una URL para el blob
        const urlBlod = window.URL.createObjectURL(blobResponse);
        // Crear un elemento de anclaje para descargar el archivo
        const link = document.createElement('a');
        link.href = urlBlod;
        link.setAttribute('download', `reporte-${currentDate}.csv`);
        // Añadir el enlace al DOM
        document.body.appendChild(link);
        // Hacer clic en el enlace para iniciar la descarga
        link.click();
        // Remover el enlace del DOM
        document.body.removeChild(link);
        setLoadingExcelDownload(false);
    }

    const onSubmit = async (form: checkEmailIcetexType) => {
        const { setCurrentPage } = tableControllers;
        let formData: FormData = new FormData();

        const formFields = ['documentId', 'email', 'phone', 'tickler', 'startDate', 'endDate'];

        formFields.forEach(field => {
            if (form[field]) {
                formData.append(field, form[field]);
            }
        });

        const filterModel = createFilterModel({
            documentId: form.documentId,
            email: form.email,
            phone: form.phone,
            tickler: form.tickler,
            dateRange: {
                startDate: form.startDate,
                endDate: form.endDate
            }
        });

        setCurrentPage(1);
        setFilterModel(filterModel as FilterModel<EmailIcetexData>[]);
    }


    //TODO: move the styles to the other file?
    return (
        <div>
            <Menu />
            <div className="d-flex justify-content-center mt-3">
                <h3>Consultar correos de Masivian</h3>
            </div>
            {/* Temporal inline style 1000px para evitar que la tabla se desordene, pendiente hacerla más responsive */}
            <Container style={{ minWidth: '1000px' }}>
                <Formik
                    validationSchema={checkEmailIcetexSchema}
                    onSubmit={onSubmit}
                    initialValues={{
                        documentId: '',
                        email: '',
                        phone: '',
                        startDate: '',
                        endDate: '',
                        tickler: '',
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, isValid, dirty }) => (
                        <formik.Form noValidate onSubmit={handleSubmit}>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col style={{ paddingBottom: '55px' }}>
                                    <Form.Group controlId="formFile" className="mb-1">
                                        <Form.Label style={{ whiteSpace: 'nowrap' }}>Documento ID</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name='documentId'
                                            onChange={handleChange}
                                            isInvalid={!!errors.documentId}
                                        />
                                        <Form.Control.Feedback type="invalid" style={{ minHeight: '1rem' }}>
                                            {errors.documentId}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col style={{ paddingBottom: '55px' }}>
                                    <Form.Group className="mb-1">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name='email'
                                            onChange={handleChange}
                                            value={values.email}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col style={{ paddingBottom: '5px' }}>
                                    <Form.Group className="mb-1">
                                        <Form.Label>Teléfono</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name='phone'
                                            onChange={handleChange}
                                            value={values.phone}
                                            isInvalid={!!errors.phone}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col style={{ paddingBottom: '5px' }}>
                                    <Form.Group className="mb-1">
                                        <Form.Label>Ticket Jira</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name='tickler'
                                            onChange={handleChange}
                                            value={values.tickler}
                                            isInvalid={!!errors.tickler}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.tickler}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col style={{ paddingBottom: '5px' }}>
                                    <Form.Group className="mb-1">
                                        <Form.Label>Fecha inicio</Form.Label>
                                        <Form.Control
                                            required
                                            type="date"
                                            name='startDate'
                                            onChange={handleChange}
                                            value={values.startDate}
                                            isInvalid={!!errors.startDate}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.startDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col style={{ paddingBottom: '5px' }}>
                                    <Form.Group className="mb-1">
                                        <Form.Label>Fecha final</Form.Label>
                                        <Form.Control
                                            required
                                            type="date"
                                            name='endDate'
                                            onChange={handleChange}
                                            value={values.endDate}
                                            isInvalid={!!errors.endDate}
                                        />
                                        <div style={{ minHeight: '3rem', color: '#dc3545' }}>
                                            {errors.endDate}
                                        </div>
                                    </Form.Group>
                                </Col>
                                {/*TODO: cuando sale mensaje de error se pierde la centrada vertical del botón */}
                                <Col style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button
                                        type="submit"
                                        disabled={!isValid || loading}
                                        style={{ position: 'absolute', top: '32px' }} >
                                        {loading ? 'Consultando...' : 'Consultar'}
                                    </Button>
                                </Col>
                            </Row>
                        </formik.Form>
                    )}
                </Formik>
                <Table<EmailIcetexData>
                    {...tableControllers}
                    columns={getColumns(onDetail)}
                    rows={process?.result || []}
                    rowsPerPage={defaultPagination.limit}
                    totalPages={process?.totalPages || 0}
                    totalRows={process?.count}
                    onDownloadExcel={onDownloadExcel}
                    downloadDisabled={() => filterModel.length === 0}
                    loading={loading}
                    loadingExcelDownload={loadingExcelDownload}
                />
            </Container>
            {showModal &&
                <CustomModal
                    showModal={showModal}
                    setShowMoadal={setShowModal}
                    emailDataDetail={emailDataDetail}
                />
            }
        </div>

    )
}
export default CheckEmailIcetex;